import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';
import Link from 'next/link';

import Layout from 'components/Layout';
import { Routes } from 'utils/routes';
import { DataCategory, getCategories } from 'data/Category';

interface NotFoundPageProps {
  categories: DataCategory[];
}

export default function NotFoundPage({ categories }: NotFoundPageProps) {
  return (
    <Layout
      categories={categories}
      title="Chyba 404"
      localizations={{ cz: '/', de: '/de', en: '/en' }}
    >
      <div className="Container">
        <div className="Error">
          <h1>Chyba 404</h1>
          <p>
            Stránka, kterou hledáte, neexistuje. Zabloudit může každý, ale jste
            na turistickém portálu a tak si s tím jistě nějak poradíme.
            Pravděpodobně se stala některá z&nbsp;následujích věcí:
          </p>
          <ul>
            <li>
              Přišli jste ze starého odkazu a na nové verzi stránek tento obsah
              chybí a nebo je jinde
            </li>
            <li>
              Popletla se Vám písmenka a udělali jste chybku při zadávání URL
              adresy
            </li>
            <li>Někdo z editorů obsah smazal</li>
          </ul>
          <p>
            <strong>Jak z toho ven?</strong>
          </p>
          <ul>
            <li>Zkuste se vrátit na cestu pomocí hlavní navigace nahoře</li>
            <li>
              Najděte co potřebujete pomocí vyhledávání (lupa vpravo nahoře)
            </li>
            <li>
              Začněte na{' '}
              <Link href={Routes.homepage} passHref legacyBehavior>
                <a>úvodní stránce</a>
              </Link>
              .
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const categories = await getCategories(locale!);
  return {
    props: {
      categories,
      ...(await serverSideTranslations(locale!, ['common'])),
    },
    revalidate: 3600,
  };
};
